<template>
  <div v-if="updating">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="linkIsInvalid || pickupIsInitiated || deliveryServiceUnavailable">
    <div class="container">
      <div>
        <div class="row animated-container">
          <div class="col-12 col-md-6 mt-md-5">
            <img src="@/assets/images/whoopsie-image-grey.png" class="w-100">
          </div>
          <div class="col-12 col-md-6 mt-md-5 align-self-center">
            <h1 class="mt-4">{{ $t('whoops') }}</h1>
            <ul class="dash mt-3">
              <li v-if="linkIsInvalid">Link is invalid</li>
              <li v-if="pickupIsInitiated">Pickup already initiated with confirmation id {{ confirmationId }}</li>
              <li v-if="deliveryServiceUnavailable">There was an error making the pickup request</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="pickupSuccess" class="container pt-4 pb-4">
      <div>
        <div class="row animated-container">
          <div class="col-12 col-md-6">
            <img src="@/assets/images/order-confirmed.png" class="w-100">
          </div>
          <div class="col-12 col-md-6 align-self-center">
            <h1>Order {{ orderId }}</h1>
            <div>Delivery has been initiated with confirmation id {{ confirmationId }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrintLabel',
  data() {
    return {
      linkIsInvalid: false,
      pickupIsInitiated: false,
      deliveryServiceUnavailable: false,
      pickupSuccess: false,
      updating: false,
      data: null,
      orderId: '',
      confirmationId: '',
    }
  },
  created: function () {
    this.OrderCarrierPickupWithToken()
  },
  computed: {},
  methods: {
    openLink(extraParcels) {
      const encodedData = encodeURIComponent(JSON.stringify(extraParcels));
      window.open(`/view-labels?data=${encodedData}`, '_blank');
    },
    OrderCarrierPickupWithToken() {
      const vm = this
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/ordercarriers/pickup/' + this.$route.params.token
      this.updating = true
      this.$http({
        method: 'GET',
        url: api_url,
        params: {
          message: 'label'
        }
      })
          .then(function (response) {
            vm.orderId = response.data.order.order_id
            vm.updating = false
            vm.openLink(response.data.parcels)
          })
          .catch(function (error,) {
            switch (error.response.status) {
              case 400:
                vm.pickupIsInitiated = true
                vm.confirmationId = error.response.data.confirmation_id
                break
              case 404:
                vm.linkIsInvalid = true
                break
            }
            vm.updating = false
          })
    }
  }
}
</script>
